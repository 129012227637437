<template>
  <b-card>

    <!-- form -->
    <b-row>
      <b-col md="12">
        <b-overlay
          :show="loading"
          rounded
          opacity="0.5"
          spinner-small
          spinner-variant="primary"
        >
          <div>
            <div
              class="section-block mb-4"
            >
              <div class="section-block__title">
                <feather-icon
                  icon="GridIcon"
                  width="24"
                  height="24"
                />
                <h2>Ports Layout</h2>
              </div>
              <PortsView
                v-if="!loading"
                :ports-layout="portsLayout"
                :set-ports-layout="setPortsLayout"
                :columns="columns"
                :rows="rows"
                :statistic-only="true"
                :handle-show-statistics="handleShowStatistics"
                :on-hover-blur-port="onHoverBlurPort"
              />
              <div class="port-preview-container">
                <div
                  class="port-preview model-page"
                  :class="{ 'hovered': hoveredPort }"
                >
                  <p v-if="hoveredPort">
                    {{ hoveredPort.interface }}
                  </p>
                </div>
              </div>
              <div
                v-if="!loading"
                class="port-view-legends"
              >
                <div class="port-view-legend">
                  <div class="port-view-legend__green square8" />
                  <span>Active</span>
                </div>
                <div class="port-view-legend">
                  <div class="port-view-legend__gray square8" />
                  <span>Inactive</span>
                </div>
                <div class="port-view-legend">
                  <div class="port-view-legend__yellow square8" />
                  <span>PoE Voltage 24v</span>
                </div>
                <div class="port-view-legend">
                  <div class="port-view-legend__orange square8" />
                  <span>PoE Voltage 48v</span>
                </div>
              </div>
            </div>
            <div
              v-if="activePort"
              class="section-block mb-4"
            >
              <div class="section-block__title">
                <feather-icon
                  icon="GlobeIcon"
                  width="24"
                  height="24"
                />
                <h2>Port: {{ activePort.slot.port.interface }}</h2>
              </div>
              <div class="neighbouring-form-container">
                <b-form-group
                  v-if="hasKey(activePort.slot.stats.interface, 'name')"
                  label="Name:"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Name:"
                    vid="name"
                    rules="max:255"
                  >
                    <b-form-input
                      v-model="activePort.slot.stats.interface.name"
                      disabled
                      :state="errors.length > 0 ? false:null"
                      placeholder="N/A"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  v-if="hasKey(activePort.slot.stats.interface, 'status')"
                  label="Status:"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Status"
                    vid="status"
                    rules="max:255"
                  >
                    <b-form-input
                      :value="activePort.slot.stats.interface.status === 'link-ok' ? 'Active' : 'Inactive'"
                      disabled
                      :state="errors.length > 0 ? false:null"
                      placeholder="N/A"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="neighbouring-form-container">
                <b-form-group
                  v-if="hasKey(activePort.slot.stats.interface, 'rate')"
                  label="Rate:"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="rate"
                    vid="rate"
                    rules="max:255"
                  >
                    <b-form-input
                      v-model="activePort.slot.stats.interface.rate"
                      disabled
                      :state="errors.length > 0 ? false:null"
                      placeholder="N/A"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  v-if="hasKey(activePort.slot.stats.interface, 'fullDuplex')"
                  label="Full Duplex:"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="duplex"
                    vid="duplex"
                    rules="max:255"
                  >
                    <b-form-input
                      :value="activePort.slot.stats.interface.fullDuplex ? 'Enabled' : 'Disabled'"
                      disabled
                      :state="errors.length > 0 ? false:null"
                      placeholder="N/A"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="neighbouring-form-container">
                <b-form-group
                  v-if="hasKey(activePort.slot.stats.interfacePoe, 'poeOutStatus')"
                  label="PoE Out Status:"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="poeOutStatus"
                    vid="poeOutStatus"
                    rules="max:255"
                  >
                    <b-form-input
                      v-model="activePort.slot.stats.interfacePoe.poeOutStatus"
                      disabled
                      :state="errors.length > 0 ? false:null"
                      placeholder="N/A"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  v-if="hasKey(activePort.slot.stats.interfacePoe, 'poeOutVoltage')"
                  label="PoE Out Voltage:"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="poeOutVoltage"
                    vid="poeOutVoltage"
                    rules="max:255"
                  >
                    <b-form-input
                      :value="activePort.slot.stats.interfacePoe.poeOutVoltage"
                      disabled
                      :state="errors.length > 0 ? false:null"
                      placeholder="N/A"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="neighbouring-form-container">
                <b-form-group
                  v-if="hasKey(activePort.slot.stats.interfacePoe, 'poeOutPower')"
                  label="PoE Out Watts:"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="poeOutPower"
                    vid="poeOutPower"
                    rules="max:255"
                  >
                    <b-form-input
                      v-model="activePort.slot.stats.interfacePoe.poeOutPower"
                      disabled
                      :state="errors.length > 0 ? false:null"
                      placeholder="N/A"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
            <div
              v-else-if="!loading"
              class="section-block mb-4 stats-placeholder"
            >
              <div class="section-block__title">
                <feather-icon
                  icon="GlobeIcon"
                  width="24"
                  height="24"
                />
                <h2>Port: Not Selected</h2>
              </div>
              <div class="stats-placeholder-content">
                <p>Please select a port to view its status.</p>
              </div>
            </div>
          </div>
        </b-overlay>
      </b-col>
      <!-- submit button -->
      <b-col
        cols="12"
        class="text-right buttons-container__left"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          ok-variant="secondary"
          @click.prevent="backToSite"
        >
          Back
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      id="cannotConnect"
      ref="cannotConnect"
      title="Connection Error"
      centered
      ok-only
      ok-variant="secondary"
      ok-title="Back to Site"
      hide-close
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @ok="backToSite"
    >
      <div class="mt-2 mb-2">
        <p>We couldn't connect to the device to fetch Port stats. Please ensure the device is online and try again.</p>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, VBToggle, BButton, BOverlay,
  BCol,
  BRow,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider } from 'vee-validate'
import axios from '../axios-resolver'
import PortsView from './PortsView.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BCard, BButton, BOverlay, PortsView, BCol, BRow, ValidationProvider, BFormInput, BFormGroup,
  },
  data() {
    return {
      siteId: this.$route.params.id,
      loading: true,
      hardwareId: this.$route.params.hardwareId,

      columns: 0,
      rows: 0,

      activePort: null,
      hoveredPort: null,

      portsLayout: [],
    }
  },
  created() {
    // fetch here
    this.fetchStats()
  },
  methods: {
    handleShowStatistics(column, row, slot) {
      this.activePort = {
        column,
        row,
        slot,
      }
    },
    hasKey(object, key) {
      return Object.prototype.hasOwnProperty.call(object, key)
    },
    onHoverBlurPort(port, isHover) {
      this.hoveredPort = isHover ? port : null
    },
    backToSite() {
      this.$router.push({ name: 'site-summary', params: { id: this.siteId } })
    },
    parseGrid(payload) {
      const { slots, size } = payload
      // first create matrix with size of columns and rows
      const matrix = Array.from({ length: size.columns }, () => Array.from({ length: size.rows }, () => ({
        column: '',
        row: '',
        port: '',
        stats: {},
      })))
      // fill it with slots row and column (slots: {row, column, port}[])
      slots.forEach(slot => {
        const { column, row } = slot
        matrix[column || 0][row || 0] = slot
      })
      this.portsLayout = matrix
      console.log('portsLayout', this.portsLayout)

      this.columns = size.columns
      this.rows = size.rows
    },
    async fetchStats() {
      try {
        const { data } = await axios.get(`/site/${this.siteId}/network/ports-statistics/${this.hardwareId}`)
        console.log('data', data)

        this.parseGrid(data.data)
      } catch (error) {
        console.warn(error)
        this.$refs.cannotConnect.show()
      } finally {
        this.loading = false
      }
    },
    setPortsLayout(newValue) {
      this.portsLayout = newValue
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
.card{
    &-header{
        padding: 0 !important;
    }
}
</style>
